/* Kontejner pro tabulku a filtry */
.tableContainer {
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  overflow-x: auto;
  max-width: 800px;
}

/* Vyhledávací pole */
.searchInput {
  flex: 1 1 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}

.disctinctSelectBox {
  display: flex;
  justify-content: space-between; /* Rovnoměrné rozdělení v řádku */
}

.selectBox {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
  /* flex: 1; */
  height: 40px;
  width: 60px;
  outline: none;
}

/* Přidáme malý pravý margin pro všechny selectBoxy kromě posledního, aby byl prostor mezi nimi */
.disctinctSelectBox > .selectBox:not(:last-child) {
  margin-right: 8px;
}

/* Při šířce viewportu 750px a méně se selectBoxy řadí pod sebe */
@media (max-width: 750px) {
  .disctinctSelectBox {
    flex-direction: column;
  }
  .selectBox {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
  .selectBox:last-child {
    margin-bottom: 0;
  }
}

/* Samotná tabulka */
.productsTable {
  width: 800px;
  display: block; /* Důležité pro useBlockLayout */
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 12px;
  /* overflow: hidden; */
  font-size: 14px;
  color: #333;
}

/* Přepis globálních pravidel pouze pro první a poslední sloupec */
.productsTable th.firstColumn,
.productsTable td.firstColumn,
.productsTable th.lastColumn,
.productsTable td.lastColumn {
  max-width: none !important;
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: unset !important;
}

.productsTable th::selection {
  background: transparent;
}

.productsTable th::-moz-selection {
  background: transparent;
}

/* Omezení maximální šířky pro buňky a hlavičky */
.productsTable th,
.productsTable td {
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Hlavička tabulky */
.productsTable thead {
  background-color: #f7f7f7;
  border-bottom: 2px solid #ddd;
}

.productsTable th {
  position: relative; /* pro resizer */
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  font-weight: 600;
}

.productsTable th:last-child {
  border-right: none;
}

.productsTable td {
  padding: 12px;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
  border-right: 1px solid #eee;
}

.productsTable td:last-child {
  border-right: none;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  background-color: transparent;
}

.resizer:hover {
  background-color: #ccc;
}

/* Hover efekt na řádky */
.productsTable tbody tr:hover {
  background-color: #fafafa;
}

/* Kontejner pro stránkování */
.paginationContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 16px;
  gap: 8px;
}

/* Tlačítka stránkování */
.pageButton {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  width: 40px;
}

.pageButton:hover {
  background-color: #f0f0f0;
}

.pageButton.active {
  background-color: #22e06c;
  color: #fff;
  border-color: #22e06c;
}

/* Kontejner pro checkbox (ukázka stylu) */
.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 400px) {
  .toEndBtn {
    display: none;
  }
}

.toggleContainer {
  display: flex;
  border-radius: 100vh;
  overflow: hidden;
  border: 1px solid #ddd; /* Jemný rámeček kolem celé skupiny tlačítek */
  margin: 10px 0;
}


.toggleButton {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 100vh;
  
  background: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;
  outline: none;
}

@media (max-width: 600px) {
  .toggleContainer {
    flex-direction: column;
    padding-block: 0.5rem;
    border-radius: 16px;
  }

  .toggleButton {
    border-radius: 12px;
  }
}
/* Hover efekt pouze pro neaktivní tlačítka */
/* .toggleButton:not(.active):hover {
  background-color: #f0f0f0;
} */

/* Pokud chcete linku mezi tlačítky, ponechte toto pravidlo */
/* .toggleButton:not(:last-child) {
  border-right: 1px solid #ddd;
} */

/* Aktivní tlačítko – bez hover efektu */
.active {
  background-color: #22e06c;
  color: #fff;
  cursor: default;
}
