.tooltip_container {
    position: absolute;
    top: -40px; /* Nastavte pozici relativně k ikoně */
    left: 0; /* Zarovnejte podle potřeby */
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    width: auto;
    max-width: 200px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
